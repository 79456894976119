#home {
  position: relative;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65; // will take 65% of the screen
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin: 0 2rem;
  padding-top: 5rem;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    background: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 115%;
      text-transform: uppercase;
      text-align: right;
      line-height: 2;
    }
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.app__header-circles {
  flex: 0.65;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  margin-left: 1rem;
  position: relative;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background: var(--white-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      height: 60%;
    }
  }

  // circles with different height & width

  div:nth-child(1) {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 8vw;
    top: 20vh;
  }

  div:nth-child(2) {
    width: 150px;
    height: 150px;
    position: absolute;
    left: 4vw;
    top: 45vh;
  }

  div:nth-child(3) {
    width: 80px;
    height: 80px;
    left: -3vw;
    bottom: 2rem;
    position: absolute;
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(1) {
      width: 200px;
      height: 200px;
    }

    div:nth-child(2) {
      width: 400px;
      height: 400px;
    }

    div:nth-child(3) {
      width: 170px;
      height: 170px;
    }
  }

  @media screen and (min-width: 1440px) {
    div:nth-child(1) {
      width: 130px;
      height: 130px;
    }

    div:nth-child(2) {
      width: 200px;
      height: 200px;
    }

    div:nth-child(3) {
      width: 100px;
      height: 100px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }

    div:nth-child(1) {
      left: inherit;
      position: inherit;
      top: inherit;
    }

    div:nth-child(2) {
      left: inherit;
      position: inherit;
      top: inherit;
    }

    div:nth-child(3) {
      left: inherit;
      position: inherit;
      bottom: inherit;
    }
  }
}

.app__header-img {
  flex: 1;
  height: auto;
  margin-left: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  img {
    // object-fit: contain;
    z-index: 1;
    margin: auto;
    // width: 100%;
    border-radius: 5rem 5rem 50rem 5rem;
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 6%;
    height: 90%;
  }

  .extra-circle1 {
    height: 100%;
    transform: none;
    border-radius: 5rem 5rem 50rem 5rem;
    box-shadow: 0px -15px 20px rgba(0, 0, 0, 0.1);
    background: transparent;
  }

  .extra-circle2 {
    height: 90%;
    transform: none;
    border-radius: 3rem 3rem 25pc 5rem;
    box-shadow: -10px -15px 20px #00000012;
    background: transparent;
    border: 5px solid #edf2f8;
    border-right: 0pc solid #edf2f8;
    border-bottom: 4pc solid #edf2f8;
  }

  .extra-circle3 {
    height: 80%;
    transform: none;
    border-radius: 4rem 4rem 30pc 5rem;
    box-shadow: -10px -15px 20px #0000000d;
    background: transparent;
    border: 5px solid #edf2f8;
    border-right: 0pc solid #edf2f8;
    border-bottom: 4pc solid #edf2f8;
  }

  // @media screen and (min-width: 1920px) {
  //   margin-top: 4rem;
  // }

  @media screen and (max-width: 1024px) {
    margin: 2rem 0;
  }
}

.app__flex .topEmail {
  text-align: center;
  background: white;
}

.app__header .new_form {
  width: 100%;
  margin: 1rem 0rem;
}

@media (max-width: 768px) {
  .profileIcon {
    max-width: 100px;
  }
}